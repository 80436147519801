import React from "react";
const Portrait = ()=>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="45 45 26 26"  xmlSpace="preserve" className="portrait">
<g>
	<ellipse className="st0" cx="55.3" cy="51.9" rx="5.7" ry="5.7"/>
	<polygon className="st0" points="50.7,60.7 59.4,60.7 64.6,69.5 45.5,69.5 	"/>
</g>
</svg>

export default Portrait;
