import React from "react";
const Analyse = ({width}) =>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 113.6 112.1" xmlSpace="preserve" className="analyse" width={width}>

<g>
	<path className="st0" d="M69,85.5c0.4,1,0.9,2.1,1.4,3.3c0.6,1.3,1.4,1.7,2.7,1.1c1.3-0.5,2.6-1.1,3.9-1.6c1.4-0.6,1.8-1.4,1.2-2.9
		c-0.5-1.1-0.9-2.3-1.3-3.1c2.1-2.1,4.1-4.1,6.2-6.2c0.9,0.4,2.1,0.9,3.2,1.3c1.3,0.5,2.2,0.1,2.7-1.1c0.6-1.3,1.1-2.7,1.7-4
		c0.5-1.3,0.1-2.2-1.1-2.8c-1.2-0.5-2.3-1-3.5-1.5c0.5-2.7,0.5-5.4,0.1-8.1c0-0.2,0.3-0.6,0.5-0.7c1-0.4,1.9-0.8,2.9-1.2
		c1.4-0.6,1.7-1.4,1.2-2.8c-0.5-1.3-1.1-2.6-1.6-3.9c-0.6-1.4-1.4-1.7-2.8-1.1c-1.1,0.5-2.3,0.9-3.1,1.3c-2.1-2.1-4.1-4.1-6.2-6.2
		c0.4-1,0.9-2.1,1.4-3.3c0.4-1.2,0.1-2.1-1.1-2.6c-1.4-0.6-2.8-1.2-4.2-1.7c-1.2-0.5-2.1-0.1-2.6,1.1c-0.4,1-0.8,2-1.2,3
		c-0.2,0.5-0.4,0.6-0.9,0.5c-1.2-0.2-2.4-0.2-3.6-0.4c-0.6-0.1-0.9,0.1-1.1,0.8c-0.4,1.7-1.5,3-3.1,3.7c-0.5,0.2-1.1,0.4-1.7,0.6
		c1,1.5,1.3,3.2,0.9,5c7.6-2.7,13.5,1.2,15.9,5.3c2.9,4.9,2.2,11.2-1.7,15.4c-3.9,4.1-10,5.2-15.1,2.6c-3.1-1.6-5.2-4-6.3-7.3
		c-1.1-3.3-0.8-6.6,0.7-9.8c-1.7,0.3-3.2-0.1-4.5-1c-1.1,3.3-2.2,4.3-6,5.1c0.1,2,0.2,4,0.3,6.1c-1.1,0.4-2.2,0.9-3.3,1.4
		c-1.3,0.6-1.7,1.4-1.1,2.7c0.5,1.3,1.1,2.6,1.7,3.9c0.6,1.3,1.4,1.7,2.7,1.1c1-0.4,1.9-0.8,2.9-1.2c0.2-0.1,0.7,0.1,0.9,0.3
		c1.4,2,3.2,3.7,5.2,5.1c0.4,0.3,0.5,0.5,0.3,1c-0.4,1-0.9,2-1.2,3c-0.4,1.2,0,2.1,1.1,2.6c1.3,0.6,2.7,1.1,4,1.7
		c1.4,0.6,2.3,0.2,2.9-1.2c0.5-1.1,0.9-2.3,1.3-3.2C63.3,85.5,66,85.5,69,85.5z M44.4,53.8c0.6-0.2,1.1-0.3,1.6-0.5
		c0.5-0.2,1.1-0.3,1.5-0.6c1.4-1.1,2.5-1,3.6,0.5c0.6,0.8,1.5,0.7,2.2,0c0.5-0.5,1.1-1,1.6-1.6c0.7-0.8,0.8-1.6,0-2.4
		c-0.5-0.5-1.1-0.9-1.5-1.2c0.9-2.1,1.5-3.7,2.3-5.5c0.6,0,1.3,0,1.9,0c1.2,0,1.7-0.5,1.7-1.7c0-0.8,0-1.5,0-2.3
		c0-1.1-0.6-1.6-1.7-1.6c-0.7,0-1.5,0-2,0c-0.8-1.9-1.5-3.5-2.3-5.4c0.5-0.4,1.1-0.9,1.7-1.5c0.6-0.7,0.5-1.5-0.3-2.2
		c-0.8-0.7-1.6-1.7-2.5-1.9c-1.4-0.3-1.8,1.4-2.7,1.9c-1.8-0.7-3.5-1.4-5.2-2.2c0-0.6,0-1.3,0-2c0-1.1-0.6-1.6-1.6-1.6
		c-0.8,0-1.6,0-2.4,0c-1.1,0-1.6,0.6-1.6,1.6c0,0.7,0,1.5,0,2.1c-1.8,0.8-3.5,1.5-5.4,2.2c-0.4-0.5-0.9-1-1.4-1.5
		c-0.7-0.6-1.5-0.6-2.2,0c-0.6,0.5-1.1,1.1-1.6,1.6c-0.7,0.8-0.7,1.6,0,2.4c0.5,0.5,1.1,0.9,1.4,1.3c-0.8,2-1.5,3.6-2.3,5.4
		c-0.7,0-1.4,0-2.1,0c-1,0-1.5,0.6-1.5,1.5c0,0.8,0,1.7,0,2.5c0,1.1,0.5,1.6,1.6,1.6c0.7,0,1.5,0,2.1,0c0.8,1.8,1.5,3.5,2.2,5.3
		c-0.4,0.4-1,0.9-1.5,1.4c-0.8,0.8-0.8,1.5,0,2.3c0.5,0.5,1,1.1,1.6,1.6c0.8,0.8,1.6,0.8,2.4,0c0.5-0.5,0.9-1.1,1.2-1.4
		c2,0.8,3.6,1.5,5.4,2.3c0,0.6,0,1.4,0,2.2c0,0.9,0.5,1.4,1.4,1.5c0.9,0,1.7,0,2.6,0c1,0,1.6-0.6,1.6-1.6
		C44.4,55.3,44.4,54.5,44.4,53.8z M64.7,71.9c4.4,0,8-3.6,8-8c0-4.5-3.6-8.1-8.2-8.1c-4.5,0-8,3.6-8,8.1
		C56.6,68.3,60.2,71.9,64.7,71.9z"/>
	<path className="st1" d="M69,85.5c-3,0-5.8,0-8.8,0c-0.4,0.9-0.8,2.1-1.3,3.2c-0.6,1.4-1.5,1.8-2.9,1.2c-1.3-0.5-2.7-1.1-4-1.7
		c-1.1-0.5-1.5-1.4-1.1-2.6c0.4-1,0.8-2,1.2-3c0.2-0.4,0.2-0.7-0.3-1c-2-1.4-3.8-3.1-5.2-5.1c-0.1-0.2-0.6-0.3-0.9-0.3
		c-1,0.3-2,0.8-2.9,1.2c-1.4,0.5-2.2,0.2-2.7-1.1c-0.6-1.3-1.1-2.6-1.7-3.9c-0.6-1.4-0.2-2.2,1.1-2.7c1.1-0.5,2.3-0.9,3.3-1.4
		c-0.1-2.1-0.2-4.1-0.3-6.1c3.9-0.8,4.9-1.8,6-5.1c1.3,0.9,2.8,1.3,4.5,1c-1.5,3.2-1.8,6.5-0.7,9.8c1.1,3.3,3.2,5.8,6.3,7.3
		c5.1,2.6,11.2,1.6,15.1-2.6c3.9-4.2,4.6-10.5,1.7-15.4c-2.4-4.1-8.3-8-15.9-5.3c0.4-1.8,0-3.4-0.9-5c0.6-0.2,1.2-0.4,1.7-0.6
		c1.6-0.8,2.7-2,3.1-3.7c0.2-0.7,0.5-0.9,1.1-0.8c1.2,0.1,2.4,0.2,3.6,0.4c0.5,0.1,0.7,0,0.9-0.5c0.4-1,0.8-2,1.2-3
		c0.5-1.2,1.4-1.6,2.6-1.1c1.4,0.6,2.8,1.1,4.2,1.7c1.1,0.5,1.5,1.4,1.1,2.6c-0.5,1.2-1,2.4-1.4,3.3c2.1,2.1,4.1,4.1,6.2,6.2
		c0.9-0.4,2-0.8,3.1-1.3c1.4-0.6,2.2-0.2,2.8,1.1c0.5,1.3,1.1,2.6,1.6,3.9c0.6,1.4,0.2,2.2-1.2,2.8c-1,0.4-2,0.8-2.9,1.2
		c-0.2,0.1-0.5,0.5-0.5,0.7c0.5,2.7,0.4,5.4-0.1,8.1c1.2,0.5,2.4,1,3.5,1.5c1.3,0.5,1.7,1.5,1.1,2.8c-0.5,1.3-1.1,2.7-1.7,4
		c-0.5,1.2-1.4,1.6-2.7,1.1c-1.1-0.5-2.3-0.9-3.2-1.3c-2.1,2.1-4,4.1-6.2,6.2c0.3,0.8,0.8,2,1.3,3.1c0.6,1.5,0.2,2.3-1.2,2.9
		c-1.3,0.5-2.6,1.1-3.9,1.6c-1.4,0.5-2.2,0.2-2.7-1.1C69.9,87.6,69.4,86.5,69,85.5z"/>
	<path className="st2" d="M44.4,53.8c0,0.8,0,1.5,0,2.2c0,1.1-0.5,1.6-1.6,1.6c-0.9,0-1.7,0-2.6,0c-0.9,0-1.4-0.6-1.4-1.5
		c0-0.8,0-1.5,0-2.2c-1.8-0.7-3.5-1.4-5.4-2.3c-0.3,0.4-0.7,0.9-1.2,1.4c-0.8,0.8-1.6,0.8-2.4,0c-0.5-0.5-1.1-1-1.6-1.6
		c-0.7-0.8-0.7-1.5,0-2.3c0.5-0.5,1-1,1.5-1.4c-0.8-1.8-1.5-3.5-2.2-5.3c-0.6,0-1.3,0-2.1,0c-1.1,0-1.6-0.6-1.6-1.6
		c0-0.8,0-1.7,0-2.5c0-0.9,0.6-1.5,1.5-1.5c0.7,0,1.5,0,2.1,0c0.7-1.8,1.4-3.5,2.3-5.4c-0.4-0.3-0.9-0.8-1.4-1.3
		c-0.8-0.8-0.8-1.6,0-2.4c0.5-0.6,1.1-1.1,1.6-1.6c0.7-0.6,1.5-0.6,2.2,0c0.5,0.5,1,1.1,1.4,1.5c1.8-0.8,3.5-1.5,5.4-2.2
		c0-0.6,0-1.3,0-2.1c0-1.1,0.6-1.6,1.6-1.6c0.8,0,1.6,0,2.4,0c1,0,1.6,0.6,1.6,1.6c0,0.7,0,1.4,0,2c1.8,0.7,3.5,1.4,5.2,2.2
		c0.9-0.5,1.4-2.2,2.7-1.9c0.9,0.2,1.7,1.2,2.5,1.9c0.8,0.7,0.9,1.6,0.3,2.2c-0.5,0.6-1.2,1.1-1.7,1.5c0.8,1.9,1.5,3.5,2.3,5.4
		c0.5,0,1.2,0,2,0c1.1,0,1.7,0.5,1.7,1.6c0,0.8,0,1.5,0,2.3c0,1.2-0.6,1.7-1.7,1.7c-0.7,0-1.4,0-1.9,0c-0.7,1.8-1.4,3.4-2.3,5.5
		c0.4,0.3,1,0.7,1.5,1.2c0.8,0.8,0.7,1.6,0,2.4c-0.5,0.5-1,1.1-1.6,1.6c-0.7,0.7-1.7,0.7-2.2,0c-1.1-1.5-0.9-2.1-2.4-1
		c-0.4,0.3-2.3,0.9-2.8,1.1C45.5,53.5,45,53.6,44.4,53.8z M48.3,39.8c0-3.7-3-6.8-6.8-6.8c-3.8,0-6.8,3-6.8,6.8c0,3.7,3,6.8,6.8,6.8
		C45.2,46.6,48.3,43.5,48.3,39.8z"/>
	<path className="st2" d="M64.7,71.9c-4.5,0-8.1-3.6-8.2-8c0-4.5,3.5-8.1,8-8.1c4.5,0,8.2,3.6,8.2,8.1C72.7,68.3,69.1,71.9,64.7,71.9z"
		/>
</g>
</svg>

export default Analyse;
