// <?xml version="1.0" encoding="utf-8"?>
// Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) 

import React from "react";

const ValeurIcon = ({width})=>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 113.6 112.1"  xmlSpace="preserve" width={width} className="valeurIcon">
<g>
	<path className="st0" d="M60.8,57.2c0,1,0,2,0,3c0,1.3-0.9,2.1-2,2.1c-1.1,0-2-0.8-2-2c0-0.9,0-1.7,0-2.6c0-0.2,0-0.3,0-0.5
		c-0.9,0-1.9,0-2.8,0c-0.3,0-0.6,0-0.8-0.1c-1-0.3-1.5-1.1-1.5-2.1c0.1-0.9,0.8-1.7,1.7-1.8c0.9-0.1,1.8,0,2.7-0.1
		c0.2,0,0.4,0,0.6,0c0-0.9,0-1.8,0-2.7c0-0.3,0-0.6,0.1-0.9c0.2-0.9,1.1-1.6,2-1.6c1,0,1.8,0.8,2,1.8c0.1,0.6,0,1.2,0,1.9
		c0,0.5,0,0.9,0,1.4c0.2,0,0.4,0,0.5,0c0.8,0,1.7,0,2.5,0c1.2,0,2.1,1,2.1,2.1c0,1.1-0.9,2-2.1,2C62.8,57.2,61.9,57.2,60.8,57.2z"/>
	<g>
		<path className="st1" d="M76.6,93.7c-5.7,0-11.3,0-17,0c0-0.1-0.1-0.1-0.1-0.2c0-3.5-0.1-7.1,0.1-10.6c0.2-4.2,1.9-7.8,4.9-10.8
			c3.5-3.5,7-7,10.5-10.5c1.5-1.5,3.6-1.5,5.1-0.1c1.4,1.4,1.3,3.6-0.1,5.1c-1.6,1.6-3.2,3.2-4.8,4.8c-0.6,0.6-0.7,1.3-0.2,1.8
			c0.5,0.5,1.2,0.5,1.8-0.2c1.9-1.9,3.7-3.7,5.6-5.6c1-1,1.4-2.1,1.3-3.5c-0.3-4.2-0.6-8.4-0.9-12.6c-0.2-2.2,0.8-3.8,2.7-4.3
			c2.1-0.6,4.3,0.8,4.5,3c0.3,2.5,0.4,5,0.6,7.5c0.2,3.2,0.5,6.4,0.7,9.6c0.2,2.7-0.8,5.1-2.7,7C84.8,78.1,81,82,77.1,85.8
			c-0.4,0.4-0.5,0.7-0.5,1.2c0,2,0,4,0,6C76.6,93.2,76.6,93.4,76.6,93.7z"/>
		<path className="st1" d="M53.9,93.7c-5.6,0-11.3,0-17,0c0-0.2,0-0.5,0-0.7c0-1.9,0-3.9,0-5.8c0-0.6-0.2-1-0.6-1.5
			c-3.8-3.7-7.5-7.5-11.3-11.3c-2.2-2.2-3.1-4.7-2.8-7.8c0.4-5.2,0.8-10.4,1.2-15.6c0.1-0.6,0.2-1.3,0.4-1.9
			c0.7-1.6,2.4-2.3,4.1-1.9c1.7,0.4,2.9,1.9,2.8,3.6c-0.1,2.3-0.3,4.6-0.4,6.9c-0.1,2.2-0.3,4.4-0.4,6.6c-0.1,1.2,0.3,2.3,1.2,3.2
			c1.9,1.9,3.8,3.8,5.7,5.7c0.6,0.6,1.3,0.6,1.8,0.1c0.5-0.5,0.5-1.2-0.1-1.8c-1.7-1.7-3.3-3.3-5-5c-1-1.1-1.3-2.3-0.8-3.7
			c0.5-1.3,1.5-2.1,2.9-2.3c1.1-0.1,2.1,0.2,2.8,1c3.7,3.6,7.4,7.2,11,11c2.8,2.9,4.4,6.5,4.6,10.5C54,86.6,53.9,90.1,53.9,93.7z"/>
		<path className="st2" d="M42.4,42.8c0,1.4,0,2.7,0,4.1c0,1.7-1.2,2.9-2.8,2.9c-1.5,0-2.8-1.2-2.8-2.7c0-1.2,0-2.4,0-3.5
			c0-0.2,0-0.4,0-0.7c-1.3,0-2.5,0-3.8,0c-0.4,0-0.8,0-1.2-0.1c-1.3-0.4-2.1-1.5-2-2.9c0.1-1.3,1.1-2.4,2.4-2.5
			c1.2-0.1,2.5-0.1,3.7-0.1c0.3,0,0.5,0,0.9,0c0-1.3,0-2.5,0-3.7c0-0.4,0-0.8,0.1-1.2c0.3-1.3,1.5-2.2,2.8-2.1c1.4,0,2.5,1,2.7,2.4
			c0.1,0.8,0.1,1.7,0.1,2.6c0,0.6,0,1.3,0,2c0.3,0,0.5,0,0.7,0c1.1,0,2.3,0,3.4,0c1.6,0.1,2.9,1.3,2.8,2.9c0,1.6-1.3,2.7-2.9,2.7
			C45.1,42.8,43.8,42.8,42.4,42.8z"/>
		<path className="st3" d="M61.2,27.4c0,1.6,0,3.1,0,4.6c0,1.9-1.4,3.3-3.2,3.3c-1.7,0-3.1-1.3-3.2-3c0-1.3,0-2.7,0-4c0-0.2,0-0.5,0-0.8
			c-1.5,0-2.9,0-4.3,0c-0.4,0-0.9,0-1.3-0.1c-1.5-0.4-2.4-1.7-2.3-3.3c0.1-1.4,1.2-2.7,2.7-2.8c1.4-0.1,2.8-0.1,4.2-0.1
			c0.3,0,0.6,0,1,0c0-1.4,0-2.8,0-4.1c0-0.4,0-0.9,0.1-1.3c0.3-1.4,1.7-2.5,3.1-2.4c1.5,0.1,2.8,1.2,3,2.7c0.1,0.9,0.1,1.9,0.1,2.9
			c0,0.7,0,1.4,0,2.2c0.3,0,0.6,0,0.8,0c1.3,0,2.6,0,3.8,0c1.8,0.1,3.2,1.5,3.2,3.2c0,1.8-1.4,3.1-3.3,3.1
			C64.2,27.4,62.7,27.4,61.2,27.4z"/>
		<path className="st2" d="M77.2,42.4c0,1.2,0,2.3,0,3.4c0,1.5-1,2.6-2.4,2.6c-1.4,0-2.4-1.1-2.4-2.5c0-1.1,0-2.3,0-3.5
			c-1.2,0-2.4,0-3.6,0c-1.4,0-2.4-1-2.4-2.3c0-1.4,1-2.5,2.4-2.5c1.2,0,2.3,0,3.6,0c0-0.7,0-1.4,0-2c0-0.6,0-1.2,0-1.8
			c0.1-1.3,1.2-2.2,2.5-2.1c1.3,0.1,2.3,1,2.3,2.3c0,1.2,0,2.4,0,3.6c0.8,0,1.6,0,2.4,0c0.4,0,0.8,0,1.2,0c1.4,0.1,2.4,1.1,2.4,2.5
			c0,1.3-1.1,2.4-2.4,2.4C79.6,42.4,78.4,42.4,77.2,42.4z"/>
	</g>
</g>
</svg>

export default ValeurIcon;
