// 

import React from "react";

const MachValueLogo = ({width}) =>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 30 376.8 100" xmlSpace="preserve" className="machValueLogo" width={width}>

<g>
	<polygon className="st0" points="15.2,46.6 31.8,46.6 44.1,63.7 44.1,87.2 	"/>
	<polygon className="st1" points="60.8,65.9 76.3,48.6 76.3,100 60.8,100 	"/>
	<polygon className="st2" points="44,87.3 44,108.4 76.3,65.8 60.7,66 	"/>
	<path className="st3" d="M145.2,104.1c0-1.1-0.7-1.8-1.8-1.8H139v5.8h0.8v-2.3h2.9l1.8,2.2l0,0h0.9l-1.7-2.3
		C144.6,105.7,145.2,105.1,145.2,104.1z M143.3,105.1h-3.6v-2h3.4c0.8,0,1.2,0.3,1.2,1C144.4,104.8,144,105.1,143.3,105.1z"/>
	<polygon className="st3" points="150.4,105.5 153.7,105.5 153.7,104.7 150.4,104.7 150.4,103.1 155.1,103.1 155.1,102.4 149.6,102.4 
		149.6,108.2 155.4,108.2 155.4,107.4 150.4,107.4 	"/>
	<polygon className="st3" points="153.4,100.6 152.5,100.6 151.9,102 152.4,102 	"/>
	<polygon className="st3" points="161.8,107.4 159.3,102.4 159.3,102.4 158.4,102.4 161.3,108.1 161.3,108.2 162.3,108.2 165.2,102.4 
		164.3,102.4 	"/>
	<polygon className="st3" points="169.7,105.5 173,105.5 173,104.7 169.7,104.7 169.7,103.1 174.4,103.1 174.4,102.4 168.9,102.4 
		168.9,108.2 174.7,108.2 174.7,107.4 169.7,107.4 	"/>
	<polygon className="st3" points="172.7,100.6 171.8,100.6 171.2,102 171.7,102 	"/>
	<polygon className="st3" points="179.5,102.4 178.7,102.4 178.7,108.2 183.8,108.2 183.8,107.4 179.5,107.4 	"/>
	<path className="st3" d="M189.9,102.4l-3.1,5.7l-0.1,0.1h0.9l0.8-1.4h3.8l0.8,1.4h0.9l-3.1-5.8H189.9z M191.9,106h-3l1.5-2.8L191.9,106
		z"/>
	<polygon className="st3" points="195.7,103.1 198.4,103.1 198.4,108.2 199.2,108.2 199.2,103.1 201.9,103.1 201.9,102.4 195.7,102.4 	
		"/>
	<polygon className="st3" points="206.3,105.5 209.6,105.5 209.6,104.7 206.3,104.7 206.3,103.1 211,103.1 211,102.4 205.5,102.4 
		205.5,108.2 211.3,108.2 211.3,107.4 206.3,107.4 	"/>
	<path className="st3" d="M220.6,106c0,1-0.8,1.5-2.3,1.5c-1.6,0-2.4-0.5-2.4-1.5v-3.7h-0.8v3.9c0,1.2,1.3,2,3.1,2c1.8,0,3.1-0.8,3.1-2
		v-3.9h-0.8V106z"/>
	<path className="st3" d="M232.2,104.1c0-1.1-0.7-1.8-1.8-1.8H226v5.8h0.8v-2.3h2.9l1.8,2.2l0,0h0.9l-1.7-2.3
		C231.7,105.7,232.2,105.1,232.2,104.1z M230.4,105.1h-3.6v-2h3.4c0.8,0,1.2,0.3,1.2,1C231.4,104.8,231,105.1,230.4,105.1z"/>
	<path className="st3" d="M245.5,102.4H242v5.8h3.7c0.8,0,1.5-0.3,2.1-0.8c0.5-0.5,0.8-1.2,0.8-2C248.6,103.5,247.4,102.4,245.5,102.4z
		 M247.7,105.3c0,0.4-0.1,0.9-0.3,1.2v0c-0.4,0.6-1,0.9-1.7,0.9h-3v-4.3h2.8C246.9,103.1,247.7,104,247.7,105.3z"/>
	<polygon className="st3" points="253.7,105.5 257,105.5 257,104.7 253.7,104.7 253.7,103.1 258.5,103.1 258.5,102.4 253,102.4 
		253,108.2 258.7,108.2 258.7,107.4 253.7,107.4 	"/>
	<polygon className="st3" points="270.5,107.4 267.9,102.4 267.9,102.4 267.1,102.4 270,108.1 270,108.2 270.9,108.2 273.9,102.4 
		273,102.4 	"/>
	<path className="st3" d="M279.5,102.4l-3.1,5.7l-0.1,0.1h0.9l0.8-1.4h3.8l0.8,1.4h0.9l-3.1-5.8H279.5z M281.5,106h-3l1.5-2.8L281.5,106
		z"/>
	<polygon className="st3" points="288.2,102.4 287.4,102.4 287.4,108.2 292.5,108.2 292.5,107.4 288.2,107.4 	"/>
	<polygon className="st3" points="297.2,105.5 300.5,105.5 300.5,104.7 297.2,104.7 297.2,103.1 302,103.1 302,102.4 296.5,102.4 
		296.5,108.2 302.2,108.2 302.2,107.4 297.2,107.4 	"/>
	<path className="st3" d="M311.8,106c0,1-0.8,1.5-2.3,1.5c-1.6,0-2.4-0.5-2.4-1.5v-3.7h-0.8v3.9c0,1.2,1.3,2,3.1,2c1.8,0,3.1-0.8,3.1-2
		v-3.9h-0.8V106z"/>
	<path className="st3" d="M322.2,105.9c1-0.2,1.6-0.8,1.6-1.7c0-1.1-0.7-1.8-1.8-1.8h-4.4v5.8h0.8v-2.3h2.9l1.8,2.2l0,0h0.9L322.2,105.9
		z M321.9,105.1h-3.6v-2h3.4c0.8,0,1.2,0.3,1.2,1C322.9,104.8,322.6,105.1,321.9,105.1z"/>
	<path className="st0" d="M238.3,92.6h-4.6l-12-20.3h5.5l8.8,14.9l8.8-14.9h5.5L238.3,92.6z"/>
	<path className="st0" d="M270.6,92.6l-2.3-4.4h-13l-2.3,4.4h-5l10.8-20.3h6.1l10.8,20.3H270.6z M261.7,76l-4.5,8.3h8.9L261.7,76z"/>
	<path className="st0" d="M278.6,92.6V72.3h4.9v16.4h15.6v3.9H278.6z"/>
	<path className="st0" d="M314.5,93.1c-7.6,0-12.3-3.1-12.3-7.4V72.3h4.9v12.8c0,2.5,2.7,4.2,7.3,4.2c4.7,0,7.3-1.7,7.3-4.2V72.3h4.9
		v13.5C326.7,90,322.1,93.1,314.5,93.1z"/>
	<path className="st0" d="M333.3,92.6V72.3h22v3.9h-17.1v4.1h11.9v3.9h-11.9v4.5h17.9v3.9H333.3z"/>
	<polygon className="st2" points="111.6,78.2 111.7,78.2 121.4,89.8 131,78.2 131.1,78.2 131.1,92.6 136,92.6 136,72.3 130.3,72.3 
		121.4,83 112.4,72.3 106.7,72.3 106.7,92.6 111.6,92.6 	"/>
	<path className="st2" d="M146.4,88.2h13l2.3,4.4h5l-10.8-20.3h-6.1l-10.8,20.3h5L146.4,88.2z M152.9,76l4.5,8.3h-8.9L152.9,76z"/>
	<path className="st2" d="M172.3,90.5c2.9,1.8,5.9,2.6,9.5,2.6c5.3,0,9.1-1.6,12.5-5.5l-4.2-2.2c-2.4,2.6-5.1,3.8-8.5,3.8
		c-5.4,0-8.9-2.8-8.9-6.8c0-3.9,3.4-6.8,8.5-6.8c3.6,0,6.6,1.4,8.9,3.7l4.5-1.6c-3.3-4.1-7.5-6-12.9-6c-4.1,0-7.7,1.2-10.3,3.3
		c-2.3,1.9-3.5,4.5-3.5,7.4C167.8,85.8,169.5,88.8,172.3,90.5z"/>
	<polygon className="st2" points="204.5,83.8 219.1,83.8 219.1,92.6 224.1,92.6 224.1,72.3 219.1,72.3 219.1,79.7 204.5,79.7 
		204.5,72.3 199.6,72.3 199.6,92.6 204.5,92.6 	"/>
</g>
</svg>


export default MachValueLogo;