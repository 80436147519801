// 
import React from "react";

const Time_To_Market = ({width}) =>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 113.6 112.1"  xmlSpace="preserve" className="time-to-market">

<g>
	<path className="st0" d="M58.1,34.5c-0.5-0.5-0.9-1-1.3-1.4c-1.5-1.4-3.2-2.1-5.3-2.1c-1.8,0-3.4,0.5-5.1,1c-2.7,0.9-5.2,2.3-7.6,4
		c-2.1,1.4-4.2,1.6-6.5,0.8c-2.1-0.7-4.1-1.4-6.2-2.2c-0.6-0.2-1.3-0.4-2-0.7c0,0.9-0.1,1.7-0.1,2.5c0,7.1,0,14.1,0,21.2
		c0,0.6-0.2,1.3,0.1,1.6c0.3,0.4,1,0.5,1.5,0.7c1.3,0.5,2.5,0.9,3.8,1.4c0.1-0.2,0.2-0.4,0.2-0.5c0.8-1.8,2.2-3.1,4-3.9
		c1.1-0.5,2.2-0.6,3.4-0.4c2,0.4,3.4,1.9,3.6,3.9c0,0.3-0.1,0.8,0.5,0.9c2.6,0.4,3.9,1.8,4.1,4.4c0,0.3,0.1,0.5,0.5,0.5
		c2.4,0.3,3.9,1.8,4.1,4.2c0,0.4,0.2,0.6,0.6,0.7c1.8,0.3,3.2,1.2,3.7,3c0.2,0.6,0.2,1.2,0.2,1.8c0,0.5,0.1,0.8,0.5,1.1
		c0.6,0.3,1.2,0.7,1.8,0.9c2.6,0.8,4.9-0.8,5.1-3.6c0-0.2,0-0.3,0.1-0.5c1.5,0.8,2.9,0.9,4.3,0.1c1.5-0.9,1.8-2.3,1.8-3.9
		c1.5,0.5,2.9,0.6,4.3-0.3c1.4-1,1.6-2.4,1.5-4c0.5,0,0.9,0.1,1.3,0.1c2,0,3.6-1.3,3.8-3.3c0.2-1.4-0.2-2.6-1.1-3.7
		c-1.8-2.3-3.5-4.6-5.4-6.8c-1.4-1.7-2.9-3.4-4.5-5c-1.6-1.7-3.1-1.9-5.2-0.9c-0.5,0.2-1,0.5-1.5,0.7c-2.6,1.3-5.1,2.5-7.7,3.8
		c-1.1,0.5-2.3,0.8-3.5,0.6c-3.2-0.6-5.3-4.2-4.5-7.5c0.4-1.6,1.4-2.7,2.8-3.5c2.8-1.6,5.7-3.3,8.6-4.9
		C57.3,34.9,57.6,34.7,58.1,34.5z M90,34.2c-1.6,0.5-3.1,1-4.6,1.5c-1.2,0.4-2.5,0.6-3.8,0.4c-1.8-0.2-3.4-0.8-5-1.7
		c-1.3-0.7-2.6-1.5-3.9-2.1c-2.7-1.3-5.4-1.1-8,0.5c-0.9,0.6-1.8,1.1-2.7,1.6c-3.9,2.2-7.8,4.3-11.7,6.5c-0.6,0.3-1.2,0.7-1.7,1.2
		c-0.5,0.5-1,1.1-1.2,1.8c-0.5,1.6,0,2.9,1.2,4c1.2,1.1,2.6,1.2,4.1,0.6c0.7-0.3,1.3-0.6,2-1c2.5-1.3,5-2.7,7.5-3.9
		c2.4-1.2,4-0.9,5.9,0.9c3,2.7,5.7,5.7,8.1,9c1.2,1.6,2.4,3.3,3.7,4.8c1.4,1.6,3.2,2.4,5.3,2.1c1.4-0.2,2.7-0.7,4.1-1
		c0.5-0.1,0.6-0.3,0.6-0.8c0-7.9,0-15.8,0-23.7C90,34.7,90,34.5,90,34.2z M45.4,75.1c-0.2,0.7-0.6,1.3-0.7,2
		c-0.6,2.2,1.3,3.9,3.4,3.5c2.2-0.5,3.8-1.8,4.4-4.1c0.7-2.5-1-4.3-3.5-3.7c-0.6,0.1-1.1,0.4-1.7,0.6c0.3-0.7,0.5-1.3,0.7-1.9
		c0.6-2-0.6-3.6-2.7-3.6c-0.7,0-1.4,0.3-2.2,0.5c-0.2,0-0.3,0.1-0.5,0.2c0.3-0.7,0.6-1.3,0.8-2c0.6-2.3-1.1-4.1-3.4-3.6
		c-0.4,0.1-0.9,0.3-1.3,0.4c-0.2,0.1-0.3,0.1-0.6,0.2c0.3-0.6,0.5-1.2,0.7-1.8c0.7-2.3-0.8-4-3.1-3.8c-2.3,0.2-4.5,2.4-4.8,4.7
		c-0.2,1.9,1,3.3,2.9,3.2c0.6,0,1.2-0.3,1.8-0.4c0.2-0.1,0.4-0.1,0.7-0.2c-0.3,0.7-0.5,1.2-0.7,1.8c-0.7,2.4,1,4.2,3.4,3.6
		c0.6-0.1,1.2-0.4,1.8-0.6c-0.3,0.7-0.5,1.3-0.7,2c-0.6,2.2,1,3.9,3.2,3.5C44.1,75.5,44.8,75.3,45.4,75.1z"/>
	<path className="st1" d="M58.1,34.5c-0.4,0.2-0.8,0.4-1.1,0.6c-2.9,1.6-5.7,3.2-8.6,4.9c-1.4,0.8-2.4,1.9-2.8,3.5
		c-0.8,3.3,1.3,6.9,4.5,7.5c1.2,0.2,2.4,0,3.5-0.6c2.6-1.3,5.1-2.5,7.7-3.8c0.5-0.2,1-0.5,1.5-0.7c2.1-1,3.6-0.8,5.2,0.9
		c1.5,1.6,3,3.3,4.5,5c1.8,2.2,3.6,4.6,5.4,6.8c0.9,1.1,1.3,2.3,1.1,3.7c-0.3,2-1.8,3.3-3.8,3.3c-0.4,0-0.8-0.1-1.3-0.1
		c0.1,1.6-0.1,3-1.5,4c-1.4,1-2.8,0.8-4.3,0.3c0,1.6-0.3,3-1.8,3.9c-1.4,0.8-2.8,0.7-4.3-0.1c0,0.2,0,0.4-0.1,0.5
		c-0.2,2.7-2.5,4.4-5.1,3.6c-0.6-0.2-1.2-0.6-1.8-0.9c-0.4-0.2-0.5-0.6-0.5-1.1c0-0.6,0-1.3-0.2-1.8c-0.5-1.8-1.9-2.8-3.7-3
		c-0.5-0.1-0.6-0.3-0.6-0.7c-0.2-2.4-1.7-3.9-4.1-4.2c-0.3,0-0.4-0.2-0.5-0.5c-0.2-2.6-1.5-4-4.1-4.4c-0.6-0.1-0.5-0.5-0.5-0.9
		c-0.2-2.1-1.5-3.5-3.6-3.9c-1.2-0.2-2.3-0.1-3.4,0.4c-1.8,0.8-3.1,2.1-4,3.9c-0.1,0.2-0.1,0.3-0.2,0.5c-1.3-0.5-2.5-0.9-3.8-1.4
		c-0.5-0.2-1.3-0.3-1.5-0.7c-0.3-0.4-0.1-1.1-0.1-1.6c0-7.1,0-14.1,0-21.2c0-0.8,0.1-1.6,0.1-2.5c0.7,0.2,1.3,0.4,2,0.7
		c2.1,0.7,4.1,1.5,6.2,2.2c2.3,0.8,4.4,0.6,6.5-0.8c2.4-1.6,4.8-3,7.6-4c1.6-0.6,3.3-1.1,5.1-1c2.1,0,3.8,0.7,5.3,2.1
		C57.2,33.5,57.6,33.9,58.1,34.5z"/>
	<path className="st2" d="M90,34.2c0,0.3,0,0.4,0,0.6c0,7.9,0,15.8,0,23.7c0,0.5-0.1,0.7-0.6,0.8c-1.4,0.3-2.7,0.8-4.1,1
		c-2.1,0.3-3.9-0.5-5.3-2.1c-1.3-1.6-2.5-3.2-3.7-4.8c-2.4-3.2-5.1-6.3-8.1-9c-2-1.7-3.6-2-5.9-0.9c-2.5,1.3-5,2.6-7.5,3.9
		c-0.6,0.3-1.3,0.7-2,1c-1.5,0.6-2.9,0.5-4.1-0.6c-1.2-1.1-1.7-2.5-1.2-4c0.2-0.7,0.7-1.3,1.2-1.8c0.5-0.5,1.1-0.8,1.7-1.2
		c3.9-2.2,7.8-4.3,11.7-6.5c0.9-0.5,1.8-1,2.7-1.6c2.6-1.7,5.3-1.8,8-0.5c1.3,0.6,2.6,1.4,3.9,2.1c1.6,0.9,3.2,1.5,5,1.7
		c1.3,0.2,2.5-0.1,3.8-0.4C86.9,35.2,88.4,34.7,90,34.2z"/>
	<path className="st2" d="M45.4,75.1c-0.7,0.2-1.4,0.4-2.1,0.6c-2.2,0.4-3.8-1.3-3.2-3.5c0.2-0.7,0.5-1.3,0.7-2
		c-0.6,0.2-1.2,0.4-1.8,0.6c-2.4,0.5-4.1-1.3-3.4-3.6c0.2-0.6,0.4-1.1,0.7-1.8c-0.3,0.1-0.5,0.1-0.7,0.2C35,65.7,34.4,66,33.8,66
		c-1.9,0.1-3.1-1.3-2.9-3.2c0.3-2.3,2.5-4.5,4.8-4.7c2.3-0.2,3.8,1.5,3.1,3.8c-0.2,0.6-0.5,1.1-0.7,1.8c0.2-0.1,0.4-0.1,0.6-0.2
		c0.4-0.1,0.9-0.3,1.3-0.4c2.3-0.5,4.1,1.3,3.4,3.6c-0.2,0.7-0.5,1.3-0.8,2c0.2-0.1,0.3-0.2,0.5-0.2c0.7-0.2,1.4-0.5,2.2-0.5
		c2,0,3.2,1.6,2.7,3.6c-0.2,0.6-0.5,1.3-0.7,1.9c0.6-0.2,1.1-0.4,1.7-0.6c2.5-0.6,4.2,1.2,3.5,3.7c-0.6,2.2-2.2,3.6-4.4,4.1
		c-2.1,0.5-4-1.2-3.4-3.5C44.9,76.4,45.2,75.8,45.4,75.1z"/>
</g>
</svg>

export default Time_To_Market;