import React from "react";
const Prospection = ()=>
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 113.6 112.1"  xmlSpace="preserve" className="prospection">

<g>
	<g>
		<path className="st0" d="M55,22c0-0.7,0-1.3,0-1.9c0-1.2,0.8-2,1.8-2.1c1.1,0,1.9,0.8,1.9,2c0,0.6,0,1.2,0,2c0.4,0,0.7,0,1.1,0
			c11.3,0,22.7,0,34,0c0.3,0,0.5,0,0.8,0c1.3,0,1.9,0.7,1.9,1.9c0,5.7,0,11.4,0,17.1c0,10.2,0,20.4,0,30.5c0,1.7-0.7,2.3-2.4,2.3
			c-7.9,0-15.9,0-23.8,0c-0.3,0-0.5,0-0.9,0c0.2,0.4,0.3,0.7,0.5,0.9c3.1,5.5,6.2,11,9.3,16.5c0.4,0.7,0.5,1.4,0,2.1
			c-0.4,0.6-1.1,0.9-1.8,0.8c-0.8-0.1-1.2-0.6-1.6-1.3c-3-5.4-6.1-10.7-9.2-16.1c-0.5-0.9-0.9-1.7-1.5-2.6c-0.1-0.2-0.4-0.4-0.6-0.4
			c-2,0-3.9,0-6,0c0,0.3,0,0.6,0,0.9c0,4.9,0,9.7,0,14.6c0,1.2-0.7,2.1-1.8,2.1c-1.2,0-1.9-0.8-2-2.1c0-4.8,0-9.6,0-14.4
			c0-0.3,0-0.6,0-1c-0.3,0-0.5,0-0.8,0c-1.6,0-3.2,0-4.7,0c-0.6,0-0.9,0.2-1.2,0.7c-3.5,6.2-7,12.3-10.5,18.4
			c-0.3,0.6-0.8,1.1-1.5,1.2c-0.7,0.1-1.3-0.2-1.8-0.8c-0.4-0.6-0.5-1.3-0.1-2c0.3-0.7,0.7-1.4,1.1-2c2.8-4.9,5.6-9.8,8.4-14.7
			c0.1-0.2,0.3-0.5,0.4-0.8c-0.4,0-0.7,0-1,0c-7.9,0-15.8,0-23.6,0c-1.8,0-2.4-0.6-2.4-2.4c0-15.7,0-31.4,0-47.1
			c0-1.8,0.6-2.4,2.4-2.4c11.4,0,22.8,0,34.3,0C54.3,22,54.6,22,55,22z M92.8,70.1c0-14.8,0-29.6,0-44.3c-24,0-47.8,0-71.8,0
			c0,14.8,0,29.5,0,44.3C45,70.1,68.9,70.1,92.8,70.1z"/>
	</g>
	<g>
		<path className="st1" d="M69.5,48.6c0.5,7-4.5,15.4-13,16c-9.3,0.7-17.2-7-16.7-16.4c0.5-8.6,8.8-13.8,16-13.4c0,4.5,0,9.1,0,13.7
			C60.4,48.6,64.9,48.6,69.5,48.6z"/>
		<path className="st2" d="M72.2,45.7c-4.4,0-8.8,0-13.2,0c0-4.4,0-8.8,0-13.2C64.7,32,72.2,37.2,72.2,45.7z"/>
	</g>
</g>
</svg>

export default Prospection;