import React, {Component} from 'react';
import { Link } from "react-router-dom";

class ArticleList extends Component{
    constructor(props) {
        super(props);
        this.list = React.createRef();
    }

    renderList=(num)=>{
       Array.from(this.list.current.children).splice(0,num).forEach(l=>{
        l.classList.remove('hidden');
       })
    }

    turnPage=(num,i)=>{
        const articleList = Array.from(this.list.current.children);
        articleList.forEach(e=>{
            e.classList.add('hidden');
        })
        const start = num*(i-1);
        articleList.splice(start,num).forEach(e=>{
            e.classList.remove('hidden');
        })
    }
    
    render(){
        return(
            <ul ref={this.list}>
                {this.props.articles.reverse().map((article)=>
                    <li key={article.id} className='hidden'>
                        <p>{article.date}</p>
                        {
                            article.url ? 
                            (<img src={article.url} alt=' Mondial du Bâtiment '/>) 
                            : 
                            (
                                <h6>{article.title}</h6>
                            )
                        }
                        {
                            article.resumeUrl ?
                            (<img src={article.resumeUrl} alt='architect@work '/>)  :
                            (<p>{article.resume}</p>)
                        }
                        
                        <Link to={`/actualites/articles/${article.id}`}>
                            LIRE LA SUITE
                        </Link>
                    </li>
                )}
            </ul>
        )
    }
}

export default ArticleList;