import React from "react";
const MachvalueHome =()=>

<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 476.3 348"  xmlSpace="preserve" className="machvalue-home">
<g>
	<path className="st0" d="M89.6,304.2c0-1.9-1.2-3.1-3.2-3.1h-7.6v10h1.3v-3.9h5l3,3.9l0,0.1h1.6l-3-4C88.7,306.9,89.6,305.8,89.6,304.2
		z M86.4,305.9h-6.2v-3.5h5.9c1.4,0,2.1,0.6,2.1,1.8C88.2,305.3,87.6,305.9,86.4,305.9z"/>
	<polygon className="st0" points="98.6,306.5 104.3,306.5 104.3,305.2 98.6,305.2 98.6,302.4 106.8,302.4 106.8,301.1 97.3,301.1 
		97.3,311.1 107.1,311.1 107.1,309.8 98.6,309.8 	"/>
	<polygon className="st0" points="103.8,298.1 102.2,298.1 101.2,300.6 102,300.6 	"/>
	<polygon className="st0" points="118.3,309.8 113.9,301.2 113.9,301.1 112.4,301.1 117.4,311.1 117.5,311.1 119.1,311.1 124.1,301.1 
		122.6,301.1 	"/>
	<polygon className="st0" points="131.8,306.5 137.5,306.5 137.5,305.2 131.8,305.2 131.8,302.4 140,302.4 140,301.1 130.5,301.1 
		130.5,311.1 140.4,311.1 140.4,309.8 131.8,309.8 	"/>
	<polygon className="st0" points="137.1,298.1 135.4,298.1 134.4,300.6 135.2,300.6 	"/>
	<polygon className="st0" points="148.7,301.1 147.4,301.1 147.4,311.1 156.2,311.1 156.2,309.8 148.7,309.8 	"/>
	<path className="st0" d="M166.7,301.1l-5.3,9.8l-0.1,0.2h1.5l1.4-2.5h6.6l1.3,2.5h1.5l-5.4-10H166.7z M170.1,307.4h-5.2l2.6-4.8
		L170.1,307.4z"/>
	<polygon className="st0" points="176.7,302.4 181.3,302.4 181.3,311.1 182.6,311.1 182.6,302.4 187.3,302.4 187.3,301.1 176.7,301.1 	
		"/>
	<polygon className="st0" points="194.9,306.5 200.6,306.5 200.6,305.2 194.9,305.2 194.9,302.4 203.1,302.4 203.1,301.1 193.6,301.1 
		193.6,311.1 203.5,311.1 203.5,309.8 194.9,309.8 	"/>
	<path className="st0" d="M219.6,307.5c0,1.7-1.4,2.5-4,2.5c-2.7,0-4.1-0.9-4.1-2.5v-6.4h-1.3v6.7c0,2.1,2.2,3.5,5.4,3.5
		c3.2,0,5.3-1.4,5.3-3.5v-6.7h-1.3V307.5z"/>
	<path className="st0" d="M239.6,304.2c0-1.9-1.2-3.1-3.2-3.1h-7.6v10h1.3v-3.9h5l3,3.9l0,0.1h1.6l-3-4
		C238.6,306.9,239.6,305.8,239.6,304.2z M236.4,305.9h-6.2v-3.5h5.9c1.4,0,2.1,0.6,2.1,1.8C238.2,305.3,237.6,305.9,236.4,305.9z"/>
	<path className="st0" d="M262.4,301.1h-6v10h6.3c1.4,0,2.7-0.5,3.6-1.4c0.9-0.9,1.5-2.2,1.5-3.5C267.8,303.1,265.7,301.1,262.4,301.1z
		 M266.3,306.2c0,0.8-0.2,1.5-0.6,2.1v0c-0.6,1-1.7,1.6-2.9,1.6h-5.1v-7.4h4.8C264.8,302.4,266.3,303.9,266.3,306.2z"/>
	<polygon className="st0" points="276.7,306.5 282.4,306.5 282.4,305.2 276.7,305.2 276.7,302.4 284.9,302.4 284.9,301.1 275.3,301.1 
		275.3,311.1 285.2,311.1 285.2,309.8 276.7,309.8 	"/>
	<polygon className="st0" points="305.5,309.8 301.2,301.2 301.1,301.1 299.6,301.1 304.7,311.1 304.7,311.1 306.3,311.1 311.4,301.1 
		309.9,301.1 	"/>
	<path className="st0" d="M321.1,301.1l-5.3,9.8l-0.1,0.2h1.5l1.4-2.5h6.6l1.3,2.5h1.5l-5.4-10H321.1z M324.5,307.4h-5.2l2.6-4.8
		L324.5,307.4z"/>
	<polygon className="st0" points="336,301.1 334.7,301.1 334.7,311.1 343.5,311.1 343.5,309.8 336,309.8 	"/>
	<polygon className="st0" points="351.6,306.5 357.3,306.5 357.3,305.2 351.6,305.2 351.6,302.4 359.8,302.4 359.8,301.1 350.3,301.1 
		350.3,311.1 360.2,311.1 360.2,309.8 351.6,309.8 	"/>
	<path className="st0" d="M376.8,307.5c0,1.7-1.4,2.5-4,2.5c-2.7,0-4.1-0.9-4.1-2.5v-6.4h-1.3v6.7c0,2.1,2.2,3.5,5.4,3.5
		c3.2,0,5.3-1.4,5.3-3.5v-6.7h-1.3V307.5z"/>
	<path className="st0" d="M394.6,307.2c1.7-0.3,2.7-1.3,2.7-3c0-1.9-1.2-3.1-3.2-3.1h-7.6v10h1.3v-3.9h5l3,3.9l0,0.1h1.6L394.6,307.2z
		 M394.1,305.9h-6.2v-3.5h5.9c1.4,0,2.1,0.6,2.1,1.8C395.9,305.3,395.3,305.9,394.1,305.9z"/>
	<path className="st1" d="M250.1,284.2h-7.9l-20.7-35h9.5l15.1,25.6l15.1-25.6h9.5L250.1,284.2z"/>
	<path className="st1" d="M305.7,284.2l-4-7.5h-22.4l-4,7.5h-8.6l18.6-35h10.5l18.5,35H305.7z M290.5,255.7l-7.7,14.3h15.4L290.5,255.7z
		"/>
	<path className="st1" d="M319.5,284.2v-35h8.5v28.2h26.9v6.7H319.5z"/>
	<path className="st1" d="M381.3,285.3c-13.1,0-21.2-5.4-21.2-12.8v-23.2h8.5v22c0,4.3,4.6,7.2,12.7,7.2s12.7-2.9,12.7-7.2v-22h8.5v23.2
		C402.5,279.9,394.4,285.3,381.3,285.3z"/>
	<path className="st1" d="M413.8,284.2v-35h37.9v6.7h-29.4v7.1h20.4v6.7h-20.4v7.7h30.8v6.7H413.8z"/>
	<polygon className="st2" points="31.8,259.4 31.9,259.4 48.6,279.5 65.2,259.4 65.4,259.4 65.4,284.2 73.9,284.2 73.9,249.3 
		63.9,249.3 48.6,267.8 33.2,249.3 23.3,249.3 23.3,284.2 31.8,284.2 	"/>
	<path className="st2" d="M91.7,276.7h22.4l4,7.5h8.6l-18.5-35H97.7l-18.6,35h8.6L91.7,276.7z M102.8,255.7l7.7,14.3H95.1L102.8,255.7z"
		/>
	<path className="st2" d="M136.3,280.7c5,3.1,10.2,4.5,16.4,4.5c9.1,0,15.7-2.8,21.5-9.4l-7.2-3.9c-4.1,4.4-8.8,6.5-14.7,6.5
		c-9.3,0-15.3-4.9-15.3-11.8c0-6.8,5.9-11.7,14.6-11.7c6.3,0,11.3,2.5,15.3,6.3l7.7-2.8c-5.6-7-12.9-10.3-22.3-10.3
		c-7,0-13.3,2.1-17.7,5.7c-4,3.3-6,7.7-6,12.7C128.6,272.6,131.6,277.8,136.3,280.7z"/>
	<polygon className="st2" points="191.8,269.2 217.1,269.2 217.1,284.2 225.6,284.2 225.6,249.3 217.1,249.3 217.1,262.1 191.8,262.1 
		191.8,249.3 183.3,249.3 183.3,284.2 191.8,284.2 	"/>
	<polygon className="st1" points="150.1,36.7 194.3,36.7 226.8,82.2 226.8,144.8 	"/>
	<polygon className="st3" points="271.3,88.3 312.7,42.1 312.7,178.9 271.3,178.9 	"/>
	<polygon className="st2" points="271,88.3 226.8,145.1 226.8,201.3 312.6,87.8 	"/>
</g>
</svg>

export default MachvalueHome;
