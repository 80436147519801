import React, { Component } from "react";
import SVGIcon from "../svgIcons/icons";

class Boite extends Component {
    render() {
        return (
            <SVGIcon name="boiteaulettre" width="35px" />
        )
    }
}

export default Boite;