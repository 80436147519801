import React, {Component} from "react";
import ReactDom from "react-dom";
import HomePage from "./homePage/homePage";
import Default from "./extra/construction";
import styles from "./scss/index.scss";
import Nous from "./Nous/nous";
import Metiers from "./metiers/metiers";
import Competences from "./competence/competences";
import Contact from "./contact/contact";
import Actualites from "./actualites/actualites";
import Articles from "./articles/articles";
import { BrowserRouter as Router, Route, Switch }  from 'react-router-dom';

class Index extends Component{
    constructor(props) {
        super(props);
        this.state = {
            language: 'fr',
        }
    }

    changeLanguage = (language) => {
        this.setState({ language });
    }

    render(){
        return (
            <Switch>
                <Route exact path="/" render={()=><HomePage language={this.state.language} changeLanguage={(lg)=>this.changeLanguage(lg)}/>} />
                <Route path="/nous" render={() =><Nous language={this.state.language} changeLanguage={(lg) => this.changeLanguage(lg)}/>} />
                <Route path="/metiers" render={() => <Metiers language={this.state.language} changeLanguage={(lg) => this.changeLanguage(lg)}/>} />
                <Route path="/competences" render={() => <Competences language={this.state.language} changeLanguage={(lg) => this.changeLanguage(lg)}/>} />
                <Route path="/contact" render={() => <Contact language={this.state.language} changeLanguage={(lg) => this.changeLanguage(lg)}/>} />
                <Route path="/actualites" exact render={() => <Actualites language={this.state.language} changeLanguage={(lg) => this.changeLanguage(lg)}/>}/>
                <Route path="/actualites/articles/:id" render={(props) => <Articles {...props} language={this.state.language} changeLanguage={(lg) => this.changeLanguage(lg)} />} />
                <Route  component={Default}/>
            </Switch>
        )
    }
}

ReactDom.render(<Router><Index /></Router>, document.getElementById("index"))