import React, {Component} from "react";


class Default extends Component{
    render(){
        return <div className="cover construction">
            <p>La page demand&eacute;e n'existe pas</p>
          </div>;
    }
}

export default Default;